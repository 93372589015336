import React from "react";
import classes from "./Sidebar.module.css";
import logo from "../images/logo-vertical-white.png";
import { IoMdClose } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { NavLink } from "react-router-dom";

import { FaPhoneAlt } from "react-icons/fa";

const Sidebar = ({ sidebarHandler, sidebar }) => {
  return (
    <>
      <div
        className={
          sidebar ? classes.sidebarOverlayActive : classes.sidebarOverlay
        }
        onClick={sidebarHandler}
      ></div>
      <div className={sidebar ? classes.sidebarActive : classes.sidebar}>
        <div className={classes.sidebarHeader}>
          <img src={logo} className={classes.logo} alt="KAPETAN logo" />
          <IoMdClose
            className={classes.closeSidebar}
            onClick={sidebarHandler}
          />
        </div>
        <hr></hr>
        <div className={classes.sidebarNavigation}>
          <ul>
            <li>
              <NavLink
                onClick={sidebarHandler}
                to={"/"}
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Početna
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={sidebarHandler}
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                to={"/onama"}
              >
                O Nama
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={sidebarHandler}
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                to={"/usluge"}
              >
                Usluge
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={sidebarHandler}
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                to={"/portfolio"}
              >
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={sidebarHandler}
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                to={"/contact"}
              >
                Kontakt
              </NavLink>
            </li>
          </ul>
        </div>
        <hr className={classes.responsiveHr}></hr>
        <div className={classes.sidebarPosition}>
          <div className={classes.sidebarContact}>
            <h3>KONTAKT:</h3>
            <div className={classes.sidebarContactContainer}>
              <FaPhoneAlt />
              <div className={classes.sidebarInfo}>
                <h3>Telefon:</h3>
                <a href="tel:+381 63 885 45 39">
                  <p>063 885 45 39</p>
                </a>
              </div>
            </div>
            <div className={classes.sidebarContactContainer}>
              <MdEmail />
              <div className={classes.sidebarInfo}>
                <h3>Email:</h3>
                <a href="mailto:contact@kapetan.rs">
                  <p>contact@kapetan.rs</p>
                </a>
              </div>
            </div>
            <div className={classes.sidebarContactContainer}>
              <FaLocationDot />
              <div className={classes.sidebarInfo}>
                <h3>Lokacija</h3>
                <p>Loznica / Novi Sad</p>
              </div>
            </div>

            <hr className={classes.sidebarHr}></hr>
            <h3 className={classes.rights}>
              <span>KAPETAN ©</span>
              <span>Sva prava rezervisana</span>
              <span> 2024</span>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
