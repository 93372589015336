import classes from "./SingleProject.module.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import Breadcrumb from "../layout/Breadcrumb";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Helmet from "../helmet/helmet";
import database from "../firebase";
import { get, ref, orderByChild, equalTo, query } from "firebase/database";
import Spinner from "../components/Spinner";
// Define your image paths
const imagePaths = {
  royal: require("../images/royal puppy/Frame 9.png"),
  gradeks: require("../images/logo-red.png"),
  gym: require("../images/f7.png"),
  omladinski: require("../images/omladinski_thumbnail.png"),
  louzivo: require("../images/louzivo.png"),
  ambulanta: require("../images/ambulanta/thumbnail-ambulanta.png"),
  klima: require("../images/master klima/3.jpg"),
  uno: require("../images/uno-thumbnail.png"),
  speltajevtic: require("../images/speltajevtic_thumbnail.png"),
  dijagnosticki: require("../images/dijagnosticki centar/centar_thumbnail.png"),
  aurora: require("../images/aurora/Frame 6.png"),
  boomerang: require("../images/boomerang/boomerang.png"),
  estegradnja: require("../images/estegradnja_thumbnail.png"),
  harmonija: require("../images/harmonija.png"),
  roda: require("../images/roda.png"),
  "daj detetu osmeh": require("../images/daj_detetu_osmeh.png"),
  triman:require("../images/triman.png"),
  kapetanija : require("../images/kapetanija.png"),
"triman automation": require("../images/triman.png")// Assuming trimanAutomation is a valid variable
};
const SingleProject = () => {
  const [singleProject, setSingleProject] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { name } = useParams();
  useEffect(() => {
    const singleProject = ref(database, "portfolio");
    const filteredProject = query(
      singleProject,
      orderByChild("thumbnail"),
      equalTo(name)
    );
    get(filteredProject).then((snapshot) => {
      if (snapshot.exists()) {
        const project = Object.entries(snapshot.val()).map(([id, data]) => ({
          id,
          ...data,
        }));
        setSingleProject(project[0]);
      } else {
        console.log("No data available");
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <>
    <Helmet title={"KAPETAN ::  " + name.toUpperCase()}> </Helmet>
    <Breadcrumb
        title={"Portfolio"}
        breadcrumb={
          <div className={classes.breadcrumbHeader}>
            <Link to={"/"}>Početna </Link>
            <MdKeyboardDoubleArrowRight /> <Link to={"/portfolio"}> Portfolio</Link>
            <MdKeyboardDoubleArrowRight /> {name.toUpperCase()}
          </div>
        }
      />
      {isLoading && <Spinner />}
      {singleProject && !isLoading && (
        <section
          className={classes.singleProjectContainer}
          data-aos-duration="1500"
          data-aos-delay="200"
          data-aos="fade-up"
        >
          <article>
            <div className={classes.thumbnailFlex}>
              <div className={classes.thumbnailImageContainer}>
                <img
                  className={classes.singleProjectThumbnail}
                  src={imagePaths[singleProject.thumbnail]}
                  alt={singleProject.name}
                />
              </div>
              <div className={classes.thumbnailDescription}>
                <h4>Klijent:</h4>
                <p>
                  <b>{singleProject.name}</b>
                </p>
                <h4>Vrsta usluge:</h4>
                <p>{singleProject.type}</p>
                <h4>Hashtag:</h4>
                <div className={classes.hashtags}>
                  {singleProject &&
                    singleProject.hashtags.map((hashtag) => {
                      return <span>{hashtag}</span>;
                    })}
                </div>
                {singleProject && singleProject.website && (
                  <>
                    <h4>Website link:</h4>
                    <p className={classes.link}>
                      <a href={singleProject.website ?? "Link"}>
                        {singleProject.website}
                      </a>
                    </p>
                  </>
                )}
              </div>
            </div>
          </article>
          <div className={classes.singleProjectDescription}>
            <h4
              className={
                classes["tp-section-subtitle"] +
                " " +
                classes["tp-yellow-color"]
              }
            >
              Portfolio
            </h4>
            <h2 className={classes["tp-section-title"]}>Opis projekta</h2>
            <p>{parse(singleProject.description) ?? "Opis za tekst"}</p>
          </div>
          {singleProject && singleProject.videos && (
            <div className={classes.projectVideos}>
              {singleProject.videos.map((video) => {
                return (
                  <video
                    muted
                    controls // This adds native video controls
                    autoPlay // This enables autoplay
                    loop
                  >
                    <source
                      src={require(`../images/${video}`)}
                      type="video/mp4"
                    />
                    {/* Add additional source elements for different video formats if needed */}
                    Your browser does not support the video tag.
                  </video>
                );
              })}
            </div>
          )}
          {singleProject && singleProject.images && (
            <div className={classes.projectImages}>
              {singleProject.images.map((image) => {
                return <img src={require(`../images/${image}`)} />;
              })}
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default SingleProject;
