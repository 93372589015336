import Partners from "./Partners";
import AllServices from "./AllServices";
import React, { useState, useEffect } from "react";
import classes from "./Home.module.css";
import Button from "./Button";
import { Link } from "react-router-dom";
import ServiceCards from "./ServiceCards";
import "./ServiceCards.css";
import Loader from "./Loader";
import PortfolioHome from "./PortfolioHome";
import Contact from "./Contact";
import Helmet from "../helmet/helmet";
const Home = () => {

  return (
    <>
    <Helmet></Helmet>
    <main className={classes.home}>
      <section className={classes.homeBg}>
        <h4 className={classes["tp-section-subtitle"]}>Više saobraćaja</h4>
        <h1 className={classes["tp-slider-title"]}>
        Revolucija u marketingu.<br></br>  Neograničen uspeh ✌️
        </h1>
        <p>
          Pridružite nam se u revolucionisanju vaših marketinških strategija za neograničen uspeh. Zajedno ćemo ostvariti vaše ciljeve.<br></br>
          Otkrijte puni potencijal vašeg poslovanja
        </p>
        <div className={classes.btnContainerHome}>
        <a href="#contact">
          <Button className={"tp-btn"}>
           Besplatna konsultacija
          </Button>
          </a>
        </div>
        <div className={classes.partners}>
          <Partners />
        </div>
        <div className={classes.serviceCardsContainer}>
          <ServiceCards
            title={"Naše usluge koje ti mogu biti od koristi!"}
            linkName={"NAŠE USLUGE"}
            icon={""}
            isGreen={true}
            href={"/usluge"}
          />
          <ServiceCards
            title={"Kontaktiraj nas i započni svoj projekat!"}
            linkName={"KONTAKT"}
            icon={""}
            isYellow={true}
            isGreen={false}
            href={"/contact"}
          />
          <ServiceCards
            title={"Pogledaj neke od naših skorašnjih projekata"}
            linkName={"NAŠI PROJEKTI"}
            icon={""}
            isBlue={true}
            href={"/portfolio"}
          />
        </div>
      </section>
      <section className={classes.services_container}>
        <h4
          className={
            classes["tp-section-subtitle"] + " " + classes["tp-yellow-color"]
          }
        >
          <span>Naše Usluge</span>
        </h4>
        <h2 className={classes["tp-section-title"]}>
          Usluge sa kojima ti možemo pomoći
        </h2>
        <div>
          <div className={classes.servicesCards}>
            <AllServices />
          </div>
        </div>
        <Button className={"tp-show-more"}>
          <Link to={"/usluge"}> DETALJNIJE</Link>
        </Button>
      </section>
      <PortfolioHome slice={true} />
      <Contact />
    </main></>
  );
};

export default Home;
